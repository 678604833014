import styled from "styled-components"

export const CardProduct = styled.div`
  position: relative;
  background: #fff;
  box-shadow: 0px 16px 48px rgba(202, 224, 236, 0.16);
  border-radius: 15px;
  padding:10px;
  margin-bottom:20px;
  & :hover {
    & img, & .gatsby-image-wrapper {
      transform: scale(1.1);
      transition: transform ease 0.5s !important;
    }
  }
`

export const CardLabel = styled.div`
  
`
export const CardThumb = styled.div`
  position: relative;
  
`
export const CardThumbInner = styled.div`
  position: relative;
  overflow:hidden;
  border-radius: 10px;
  a{
    display:flex;
    & img, & .gatsby-image-wrapper {
      width:100%;
      border-radius: 10px;
    }
  }
`

export const ProductId = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  background: #062c44;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 30px;
  display: flex;
  justify-content:center;
  align-items: center;
  padding:5px 15px;
  z-index:1;
`

export const BtnComparison = styled.div`
  position: absolute;
  z-index:1;
  bottom:0;
  left:50%;
  transform:translateX(-50%);
  background: #062c44;
  color: #fff;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  text-align: center;
  display: flex;
  justify-content:center;
  align-items: center;
  padding:5px;
  border-radius: 50%;
  width:40px;
  height:40px;
  bottom: -20px;
  cursor: pointer;
  transition: 0.5s ease-in-out;
  > svg{
    fill:#fff;
  }
  &:hover, &:focus{
    background-color:#FF3E00;
  }
`

export const CardBody = styled.div`  
    padding: 20px 0;
  @media (min-width:768px) {
    padding: 20px 0;
  }
  @media (min-width:992px) {
    padding: 20px 0;
  }
  @media (min-width:1200px) {
    padding: 20px 0;
  }
`
export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #ADCBDD;
  margin: 10px 0;
`
export const ProductName = styled.div`
  text-transform: capitalize;
  color:#FF3E00;
  font-weight: 700;
  text-align: center;
  margin-bottom:5px;

  font-size: 18px;
  line-height: 28px;
  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (min-width: 992px) {
    font-size: 24px;
    line-height: 34px;
  }
  @media (min-width: 1200px) {
    font-size: 26px;
    line-height: 34px;
  }
  > a {
    color:#FF3E00;
    &:hover,
    &:focus {
      color: #062c44;
    }
  }
`

export const ProductPriceInfo = styled.div`
  display:flex;
  flex-wrap: wrap;
  justify-content:center;
  align-items:center;
  margin-bottom:10px;
  @media (min-width: 576px) {
    margin:0 -5px 10px -5px;
  }
`
export const ProductLabelText = styled.div`
  color: #062c44;
  font-weight:500;
  font-size: 14px;
  line-height: 20px;
  @media (min-width: 576px) {
    padding:0 5px;    
  }
  @media (min-width: 1200px) {
    font-size: 16px;
    line-height: 26px;
  }
`

export const OriginPrice = styled.div`
    position: relative;
    font-weight: 700;
    color: #062c44;
    font-size: 16px;
    line-height: 26px;
    padding:0 5px;
    @media (min-width: 1200px) {
      font-size: 18px;
      line-height: 28px;
    }
    @media (min-width: 1600px) {
      font-size: 20px;
      line-height: 30px;
    }

  &.disccounted::before {
    content: "";
    position: absolute;
    background-color: #FF3E00;
    width: 100%;
    height: 3px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-8deg);
  }
`
export const DisccountedPrice = styled.div`
  font-weight: 800;
  color:#062c44;
  font-size: 18px;
  line-height: 28px;
  @media (min-width: 576px) {
    padding:0 5px;    
  }
  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (min-width: 992px) {
    font-size: 24px;
    line-height: 34px;
  }
  @media (min-width: 1200px) {
    font-size: 28px;
    line-height: 38px;
  }
`


export const ProductInfo = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content:center;  
  flex-direction: row;

`

export const ProductToolbar = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  margin-left:${props => props.ml};
  margin-right:${props => props.mr};
`
ProductToolbar.defaultProps = {  
  ml: "0",
  mr: "0",
}
export const ProductItemToolbar = styled.div`
  position:relative;
  padding:0 5px;
`
export const ProductPriceDesc = styled.div` 
  font-size: 12px;
  line-height:20px;
  text-align: center;
  color: #8CB7CE;
`
export const ProductService = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content:center;
  margin:0 -5px 15px -5px;
`

export const ProductItemService = styled.div`
  display:flex;
  align-items:center;

  position:relative;
  padding:0 5px;
  .icon{
    display:flex;
    align-items:center;
    width:18px;
    height:18px;
    @media(min-width: 992px){
      width:24px;
      height:24px;
    }
    > svg{
      fill:#8CB7CE;
    }
    + .text{      
      margin-left:5px;
    }
  }
  .text{
    font-weight: 500;
    color:#062c44;
    font-size:12px;
    line-height:18px;
    @media(min-width: 768px){
      font-size:14px;
      line-height:20px;
    }
    @media(min-width: 992px){
      font-size:16px;
      line-height:24px;
    }
  }
`

export const DisccountTape = styled.div`
  position:absolute;
  z-index:1;
  top:0;
  right:0;
`

export const TipOver = styled.div`
  position:absolute;
  z-index:1;
  left:0;
  right:0;
  text-align:center;
  background-color:rgba(255,255,255,0.6);
  padding:5px 10px; 
  width:100%;
  margin:0 auto;
  bottom:0;
  border-radius:0;
  @media(min-width: 768px){
    max-width:324px;
    border-radius:30px;
    bottom:15px;
  }
  span{
    font-size:14px;
    line-height:20px;
    font-weight:500;
    color:#062c44;
    display:inline-flex;
    position:relative;
    padding:0 6px;
    position:relative;
    + span{
      &:before{
        content:'';        
        display:inline-block;
        position:absolute;
        top:50%;
        left:0;
        transform:translateY(-50%);
        width:1px;
        height:16px;
        background-color:#062c44;
      }
    }
  }
`

export const CompareAction = styled.div`
  position:absolute;
  z-index:1;
  left:0;
  right:0;
  text-align:center;
  background-color:rgba(255,255,255,0.6);
  padding:5px 10px; 
  width:100%;
  margin:0 auto;
  bottom:0;
`





